<template>
  <div class="container" id="container">
    <div class="top">
      <div class="jf">可用积分<span>{{integral}}</span></div>
      <div class="dropdown">
        <div class="checked" @click="showDropdown = !showDropdown">{{typeList[type]}}<img src="../assets/img/icon-blackdropdown.png" /></div>
        <div class="dropdownlist" v-show="showDropdown">
          <ul>
            <li v-for="(item, index) in typeList" :key="index" :class="index == type ? 'active' : ''" @click="changeType(index)">{{item}}</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="list" id="list" v-show="list.length > 0">
      <div class="item" v-for="(item, index) in list" :key="index">
        <div class="time">{{item.createTime}}</div>
        <div class="detail">
          <div class="l">
            <h1>{{item.appName}}</h1>
            <p>【订单编号】{{item.bizId}}</p>
          </div>
          <div class="r"><span v-show="item.changeType == 1">+{{item.point}}</span><span v-show="item.changeType == 2">-{{item.point}}</span>积分</div>
        </div>
      </div>
      <div class="nomore" v-show="list.length > 0 && totalPage == pageIndex"><span>没有了哦~</span></div>
    </div>
    <div class="nodata" v-show="list.length < 1">
      <div class="box">
        <img src="../assets/img/noIntegral.png" alt="暂无积分" />
        <p>暂无数据</p>
      </div>
    </div>
    <a class="back" href="javascript:;" @click="back">返回</a>
  </div>
</template>
<script>
  export default {
    name: 'Integral',
    data () {
      return{
        apis: {
          queryPoint: 'pointCenter/queryPoint', // 积分查询
          queryChangeDetail: 'pointCenter/queryChangeDetail' // 积分列表
        },
        integral: 0, // 可用积分
        typeList: ['全部积分','积分增加','积分减少'],
        type: 0, // 查询模式
        showDropdown: false, // 显示下拉
        pageIndex: 1, //当前页面
        pageSize: 10, // 每页显示数量
        totalPage: 1, // 总页数
        loading: false, // 正在获取数据
        list: []
      }
    },
    created(){

    },
    mounted() {
      console.log('我的积分')
      this.queryPoint()
      this.queryChangeDetail()
      //监听scroll事件
      window.addEventListener('scroll', this.handleScroll, true);
    },
    destroyed() { //页面离开后销毁，防止切换路由后上一个页面监听scroll滚动事件会在新页面报错问题
      window.removeEventListener('scroll', this.handleScroll)
    },
    methods: {
      // 返回
      back() {
        this.$router.go(-1)
      },
      // 积分查询
      queryPoint() {
        const me = this;
        this.$post({
          url: this.apis.queryPoint,
          params:{
            token: this.$global.token
          }
        }).then(rsp => {
          console.log(rsp)
          if(rsp.data.data){
            me.integral = rsp.data.data.currentIntegral
          }
        })
      },
      // 积分列表查询
      queryChangeDetail() {
        const me = this;
        let type = ''
        if(me.type != 0){
          type = me.type
        }
        this.loading = true
        this.$post({
          url: this.apis.queryChangeDetail,
          params:{
            token: this.$global.token,
            pointChangeType: type,
            pageIndex: me.pageIndex,
            pageSize: me.pageSize
          }
        }).then(rsp => {
          me.loading = false
          console.log(rsp)
          if(rsp.data.data){
            me.totalPage = Math.ceil(rsp.data.data.recordCount / me.pageSize)
            me.list = me.list.concat(rsp.data.data.rows);
          }
        }).catch( error => {
          me.loading = false
        })
      },
      // 筛选
      changeType(index) {
        if(this.type != index){
          this.type = index;
          this.pageIndex = 1;
          this.list = [];
          this.queryChangeDetail();
        }
        this.showDropdown = false
      },
      // 监听滚动条
      handleScroll() {
        let scrollTop = document.getElementById('container').scrollTop
        let windowHeight = document.getElementById('container').clientHeight
        let bodyHeight = document.getElementById('list') ? document.getElementById('list').clientHeight - 10 : 0;
        if(scrollTop + windowHeight >= bodyHeight && !this.loading){
          if(this.pageIndex < this.totalPage){
            this.pageIndex++
            this.queryChangeDetail()
          }
        }
      }
    }
  }
</script>
<style scoped lang="scss">
  .container{
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    background:  #F7F7F7;
    padding-bottom: 50px;
    box-sizing: border-box;
    overflow-y: auto;
    .top{
      line-height: 50px;
      border-bottom: 1px solid #dedede;
      padding: 0 15px;
      box-sizing: border-box;
      font-size: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #333;
      span{
        margin-left: 10px;
        font-size: 18px;
        line-height: 0;
      }
      .dropdown{
        cursor: pointer;
        line-height: 30px;
        position: relative;
        border: 1px solid #C0C0C0;
        border-radius: 15px;
        padding: 0 15px;
        text-align: center;
        .checked{
          font-size: 15px;
        }
        img{
          height: 10px;
          vertical-align: middle;
          margin-left: 5px;
        }
        .dropdownlist{
          position: absolute;
          width: calc(100% - 30px);
          background: #f5f5f5;
          box-shadow: 0 0 5px #c0c0c0;
          font-size: 14px;
          box-sizing: border-box;
          animation: dropdown 0.2s ease;
          .active{
            color: #fff;
            background: #c0c0c0;
          }
        }
        @keyframes dropdown{
          0%{
            transform: translateY(20px);
            opacity: 0.5;
          }
          100%{
            transform: translateY(0px);
            opacity: 1;
          }
        }

      }
    }
    .list{
      padding: 0 15px 15px 15px;
      box-sizing: border-box;
      .item{
        margin-top: 15px;
        background: #fff;
        display: inline-block;
        width: 100%;
        border-radius: 5px;
        .time{
          background: #E5E5E5;
          border-radius: 5px 5px 0 0;
          padding: 0 10px;
          box-sizing: border-box;
          line-height: 30px;
        }
        .detail{
          padding: 10px;
          box-sizing: border-box;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .l h1{
            font-size: 16px;
          }
          .r span{
            font-size: 20px;
            font-weight: bold;
            margin-right: 5px;
          }
        }
      }
      .nomore{
        text-align: center;
        height: 0;
        line-height: 0;
        border-bottom: 1px solid #e5e5e5;
        margin: 20px 0;
        span{
          background: #f7f7f7;
          padding: 0 10px;
        }
      }
    }
    .nodata{
      display: none;
      height: calc(100vh - 101px);
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      img{
        width: 100px;
      }
    }
    .back{
      width: 100%;
      height: 50px;
      line-height: 50px;
      background: linear-gradient(to right, #7ddb4f, #21dd53);
      color: #fff;
      text-align: center;
      font-size: 18px;
      position: fixed;
      bottom: 0;
    }
  }
</style>
